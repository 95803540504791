@import url(https://fonts.googleapis.com/css2?family=Lato:wght@100;400&display=swap);
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu',
    'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

.App {
  text-align: center;
  min-height: 100vh;
  height: 100vh;
}

* {
  font-family: 'Lato', sans-serif;
}

html {
  font-family: 'Lato', sans-serif;
  color: #666666;
  font-size: 16px;
  scroll-behavior: smooth;
}

.wv-bordered-div label:not(.tooltip-button),
.rcds-popup label {
  font-family: 'Lato', sans-serif;
  color: #666666;
  font-size: 16px;
}

::-webkit-input-placeholder {
  font-family: 'Lato', sans-serif;
  color: #898686;
}

input::-webkit-input-placeholder {
  font-family: 'Lato', sans-serif;
  color: #898686;
}

input::placeholder,
.p-multiselect-label {
  font-family: 'Lato', sans-serif;
  color: #898686;
}

.p-dropdown-label {
  font-family: 'Lato', sans-serif !important;
  color: #898686 !important;
}

.p-inputwrapper-filled .p-dropdown-label {
  color: #495057 !important;
}

.p-inputtext,
.p-dropdown,
.p-datepicker-trigger {
  height: 40px;
}

.p-dropdown,
.p-datepicker-trigger {
  border: 1px solid #999999 !important;
}

.p-dropdown-panel .p-dropdown-items .p-dropdown-item.p-highlight,
.p-multiselect-panel .p-multiselect-items .p-multiselect-item.p-highlight {
  background: none !important;
}

.App-header {
  height: 15vh;
  display: flex;
  flex-direction: row;
  font-size: calc(10px + 2vmin);
  background: #fff;
  padding-top: 15px;
  width: 100%;
  z-index: 1111;

}

#header_btn1 {
  background: transparent;
  border: none;
}



.p-dropdown span {
  line-height: 23px;
  text-align: left;
}

.fieldStyle {
  font-size: medium !important;
}

input,textarea {
  width: 63%;
  border: 1px solid #999999 !important;
}

.p-calendar input {
  padding: 5px;
}

.p-checkbox:not(.p-checkbox-disabled) .p-checkbox-box.p-highlight:hover {
  border-color: #fe6b01 !important;
  background: #fe6b01 ip !important;
  color: #ffffff;
}

.p-checkbox .p-checkbox-box.p-highlight:not(.sponsoredChild-check .p-highlight) {
  border-color: #fe6b01 !important;
  background: #fe6b01 !important;
}

.form-check-input:checked {
  background-color: white !important;
  border-color: #fe6b01 !important;
}

.p-checkbox:not(.p-checkbox-disabled) .p-checkbox-box:hover {
  border-color: #fe6b01 !important;
}

.basecontainer {
  width: 95%;
  margin: auto;
  padding-top: 55px;
}

.main-content-align {
  width: 97%;
  margin: auto;
  padding-top: 22px;
}

.err-msg,
.mand-field-msg {
  margin-bottom: 0px;
  text-align: left;
  color: #cc1d00 !important;
  font-family: 'Lato', sans-serif;
  font-size: 11px;
  font-weight: 600;
}
.err-msg span,
.mand-field-msg span {
  font-size: 11px;
  font-weight: 600;
}
.csm-header {
  display: flex;
  align-items: unset;
  grid-gap: 10px;
}

.csm-header .pi-angle-left {
  font-size: 1.5rem;
  color: gray;
  line-height: 32px;
  cursor: pointer;
  padding-top: -1px;
}


.header-split .navbar-brand,
.mobile-us-header .navbar-brand,
.us-mobile-div .navbar-brand {
  font-weight: bold !important;
}

.wv-header-div {
  display: flex;
  align-items: unset;
  grid-gap: 10px;
}

.wv-header-div .pi-angle-left,
.header-split .pi-angle-left {
  font-size: 1.5rem;
  color: gray;
  line-height: 32px;
  cursor: pointer;
  padding-top: -1px;
}

.header-split button:first-child {
  margin-left: -5px;
  justify-content: center;
}

.mand-field {
  color: #cc1d00 !important;
  font-family: 'Lato', sans-serif;
  font-size: 18px;
}

.disable {
  pointer-events: none;
  opacity: 0.7;
}

.custom-switch-aggrid input {
  width: 40px !important;
  height: 20px;
}

a {
  text-decoration: none !important;
  color: #00accb;
  cursor: pointer;
}
.table-link {
  color: #00accb !important;
}
.title-cl {
  color: #7d7a7a;
}

.popup-wd {
  width: 32%;
}

.header-split {
  display: flex;
  justify-content: space-between;
  align-items: baseline;
  margin-top: 3%;
}
.pcrmHeaderSplit {
  display: flex;
  justify-content: space-between;
  align-items: baseline;
  margin-top: 0%;
}
.pcrmHeaderSplit .navbar-brand,
.mobile-us-header .navbar-brand,
.us-mobile-div .navbar-brand {
  font-weight: bold !important;
}

.cl-light {
  color: #898787;
}

.wv-collapsible a {
  background: #acdbcc !important;
  border: 2px solid white !important;
}

a:focus {
  box-shadow: none !important;
}

.p-disabled:not(.p-button, .p-datepicker-calendar .p-disabled, .p-inputswitch) {
  background: #cfcdcd6e !important;
}

.p-datepicker-trigger:focus,
.p-datepicker-trigger:hover {
  background-color: transparent !important;
  box-shadow: none !important;
  border: 1px solid #999 !important;
}

.wv-bordered-div {
  border: 1px solid #999;
  border-radius: 6px;
}

.p-checkbox .p-checkbox-box {
  border: 1px solid #999 !important;
}

.ag-header-container,
.ag-pinned-right-header,
.ag-header-viewport {
  background: #daf1ea !important;
  height: 50px !important;
}

.ag-header-row-column {
  background: #daf1ea !important;
}

.ag-header-container .ag-header-cell-text,
.ag-pinned-right-header .ag-header-cell-text,
.ag-header-viewport .ag-header-cell-text,
.ag-header-row-column {
  color: #333333 !important;
}

.mand-field-para {
  color: #898686;
}

input {
  font-family: inherit;
}

.hidden {
  display: none !important;
}

.p-button.p-button-warning,
.p-confirm-dialog-accept {
  background: #ff6b00 !important;
  color: white !important;
}

.wv-light-btn {
  background: #00accb !important;
}

.p-confirm-dialog-accept {
  border: none !important;
}

.p-button-secondary:not(.table-icon) {
  color: #333333 !important;
}

.wv-cancel-btn,
.p-confirm-dialog-reject {
  background: #ccc !important;
  color: black !important;
  border: 1px solid #cccccc !important;
}

.wv-cancel-dark-btn {
  background-color: #666666 !important;
}

.wv-cancel-dark-btn span {
  color: white !important;
}

.excel-icon {
  background-repeat: no-repeat !important;
  background-position: 10% !important;
}



.p-datepicker-trigger {
  border: 1px solid #999 !important;
  background: white !important;
  border-left: none;
}

.darker-divider.p-divider.p-divider-horizontal:before {
  border-top: 1px solid #999 !important;
}

.pi-calendar {
  color: grey;
}

.p-calendar input {
  border-right: none !important;
}

.pi-spin {
  -webkit-animation: fa-spin 1s infinite ease-out !important;
          animation: fa-spin 1s infinite ease-out !important;
}

.pi-spinner {
  color: #ff6b00 !important;
  font-size: 40px;
}

.wv-lbl-light {
  color: rgba(102, 102, 102, 1) !important;
}

.age-grid {
  margin-top: -28px !important;
  padding: 4px;
  border: 1px solid #999;
  margin-right: 0% !important;
  border-radius: 3px;
}

.age-grid input {
  width: 90%;
}

.locked-col {
  background: #d3d1d1cf;
  border: none;
  border-left: 13px solid white !important;
  border-right: 16px solid white !important;
}

.wv-table table thead tr th {
  background: #cee5de !important;
}

.p-radiobutton .p-radiobutton-box.p-highlight {
  border-color: #fe7501 !important;
  background: #fe7501 !important;
}

.ag-side-buttons {
  display: none;
}

.transfer_child .ag-theme-balham .ag-checkbox-input-wrapper:active {
  box-shadow: none !important;
}

.transfer_child .ag-row .ag-cell-wrapper .ag-selection-checkbox .ag-input-wrapper .ag-checked,
.transfer_child .ag-row .ag-cell-wrapper .ag-selection-checkbox .ag-input-wrapper {
  background-color: transparent;
  box-shadow: none !important;
}

.transfer_child .ag-row .ag-cell-wrapper .ag-selection-checkbox .ag-input-wrapper::after {
  content: '\25EF';
  font-size: 16px;
  left: 16px;
  top: -3px;
}

.transfer_child .ag-row .ag-cell-wrapper .ag-selection-checkbox .ag-checked::after {
  content: '\25C9' !important;
  font-size: 25px;
  left: 15px;
  top: -4px;
}

.project-div {
  padding-top: 4.5%;
}

.mobile-mand-para {
  display: none;
}



button span {
  font-family: 'Lato', sans-serif;
}

.p-multiselect-items li:hover,
.p-dropdown-items li:not(.p-dropdown-empty-message):hover,
.p-dropdown-items .p-highlight,
.p-multiselect-items .p-highlight {
  color: #fe7501 !important;
}

.ag-floating-bottom-container {
  text-align: left;
}

.sec-header {
  font-size: 1.1rem;
  margin-bottom: 0px !important;
  font-weight: bold;
}

.form-switch .form-check-input:checked,
.form-switch .form-check-input:focus {
  background-image: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'><circle r='3' fill='rgb(255,107,0)'/></svg>") !important;
}

.pi-exclamation-triangle {
  color: #d4422a;
}

.p-message.p-message-success {
  background: #b5e4d4 !important;
  border: none !important;
}

.p-message.p-message-error {
  border: none !important;
}

.p-message .p-message-wrapper {
  justify-content: center !important;
  display: inline-flex !important;
}

.mobile-wid .p-confirm-dialog-message {
  font-size: 18px;
}

@media only screen and (min-width: 300px) and (max-width: 540px) {
  .main-content-align {
    width: 95%;
    padding-top: 13%;
  }

  .basic-search-split {
    flex-direction: column;
    width: 94%;
  }

  .rowPadding {
    grid-gap: 15px;
    gap: 15px;
  }

  .bs-mobile-grid {
    flex-direction: column;
    width: 100%;
    margin-left: 0px;
    margin-right: 0px;
  }

  .bs-mobile-grid .p-calendar {
    width: 95% !important;
  }

  .res-mobile-grid {
    grid-gap: 15px;
    gap: 15px;
  }

  .popup-wd,
  .mobile-wid {
    width: 96% !important;
  }

  .mobile-wid .p-confirm-dialog-message {
    font-size: 20px;
  }

  .popup-wd .p-dialog-footer,
  .mobile-wid .p-dialog-footer {
    padding: 10px;
  }

  .mobile-table-scroll {
    overflow-x: scroll;
  }

  .project-div {
    padding-top: 12% !important;
  }

  .navbar-brand {
    font-weight: 500;
  }

  .mand-field-para {
    display: block;
    margin-bottom: 0px;
    width: 98%;
  }

  .lg-screen-mand {
    display: none;
  }

  .p-inputtext {
    width: 100% !important;
  }

  .p-calendar input {
    border-right: 1px solid #999 !important;
  }

  .please-note-div {
    width: 100% !important;
  }
  .please-note-div p {
    text-align: left !important;
  }
}

#center_loader {
  position: fixed;
  top: 9%;
  width: 100vw;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.4);
  transition-duration: 0.1s;
  z-index: 1101;
  left: 0;
}

#center_loader div:first-child,
#center_loader_app div:first-child {
  justify-content: center;
  position: absolute;
  top: 35%;
  right: 47.2vw;
}

.inner-scroll::-webkit-scrollbar,
.p-datatable-scrollable-body::-webkit-scrollbar,
:is(.p-picklist-source, .p-picklist-target, .legal-popup .p-dialog-content)::-webkit-scrollbar {
  width: 5px;
}

.inner-scroll::-webkit-scrollbar-track .p-datatable-scrollable-body::-webkit-scrollbar-track,
:is(.p-picklist-source, .p-picklist-target, .legal-popup
    .p-dialog-content)::-webkit-scrollbar-track {
  background: #f1f1f1;
}

.inner-scroll::-webkit-scrollbar-thumb,
.p-datatable-scrollable-body::-webkit-scrollbar-thumb,
:is(.p-picklist-source, .p-picklist-target, .legal-popup
    .p-dialog-content)::-webkit-scrollbar-thumb {
  background: rgba(136, 136, 136, 0.658);
  border-radius: 10px;
}


.please-note-div {
  text-align: initial;
  margin-left: auto;
}

.please-note-div p {
  color: #777777;
  line-height: 18px;
  font-size: 14px;
}

.please-note-div span {
  font-weight: bold;
}

.p-dialog-header {
  padding-top: 1rem !important;
  padding-bottom: 1rem !important;
}

.p-button-link {
  color: #00accb !important;
}

.p-dialog-header-close:focus,
.p-paginator-element:focus {
  box-shadow: none !important;
}

.wv-bordered-div.table-content {
  padding-left: 12px;
  padding-right: 12px;
}

.p-confirm-dialog .p-dialog-title,
.p-confirm-dialog .p-confirm-dialog-message {
  font-size: 16px !important;
}

.p-confirm-dialog .p-dialog-header {
  padding-bottom: 12px !important;
}

.p-confirm-dialog .p-dialog-footer {
  padding-bottom: 17px !important;
}

.p-confirm-dialog .p-dialog-content {
  padding-bottom: 20px !important;
}

.p-datatable tbody .p-row-odd {
  background-color: #f9f9f9 !important;
}

.p-datatable tbody tr {
  background: white !important;
}

.p-confirm-dialog-reject:active,
.p-confirm-dialog-reject:focus,
.p-confirm-dialog-accept:focus,
.p-confirm-dialog-accept:active {
  box-shadow: none !important;
}

.p-checkbox-box.p-focus {
  outline: none !important;
  box-shadow: none !important;
}

.p-button:not(.table-icon, .p-button-icon-only, .res-link, .ads-link) {
  padding-top: 5px !important;
  padding-bottom: 5px !important;
  padding-left: 25px !important;
  padding-right: 25px !important;
}
.p-datatable-scrollable-header-box {
  margin-right: 0px !important;
  margin-left: 0px;
}
::selection {
  background-color: #ff6b00 !important;
  color: white;
}
.p-multiselect-label .p-chip {
  border-radius: 4px;
}
.wv-collapsible .p-accordion-header-link {
  border-left-width: 0px !important;
  border-right-width: 0px !important;
}
.p-multiselect-items .p-multiselect-item {
  white-space: normal;
}
.tooltip-design-overlay {
  background-color: #333333e3 !important;
  color: white !important;
  padding: 5px;
  max-width: 25%;
  font-size: 14px !important;
  padding-top: 10px;
  padding-bottom: 10px;
}
.tooltip-design-overlay::before,
.tooltip-design-overlay::after {
  border-bottom-color: #00000063 !important;
}
.p-inputtext:enabled:focus {
  border: 1px solid #2196f3 !important;
  box-shadow: none !important;
}
.p-dropdown:not(.p-disabled).p-focus {
  outline: 0 none;
  outline-offset: 0;
  border-color: #2196f3 !important;
  box-shadow: none !important;
}

.p-dialog-footer {
  display: flex;
  justify-content: center;
}

.invalid-err-border,
.invalid-err-border:focus,
.invalid-err-border:hover,
.invalid-err-border.p-inputtext:enabled:focus {
  border: 1px solid #cc1d00 !important;
}
.invalid-err-border.date {
  border: none !important;
  outline: 1px solid #cc1d00 !important;
  border-radius: 2px;
}
.invalid-err-border.date input {
  border: none !important;
  border-right: 1px solid #cc1d00 !important;
}
.invalid-err-border.date button {
  border: none !important;
}
.p-dialog-bottom-left {
  background-color: transparent !important;
}
.export-toaster-info .p-dialog-content {
  background-color: #b3e5fc !important;
  border: solid #0891cf;
  border-width: 0 0 0 6px;
  color: #044868;
  padding: 10px 10px 10px 10px !important;
  border-radius: 6px;
}
.export-toaster-success .p-dialog-content {
  background-color: #b3e5fc !important;
  border: solid #0891cf;
  border-width: 0 0 0 6px;
  color: #044868;
  padding: 10px 10px 10px 10px !important;
  border-radius: 6px;
}
.export-toaster .p-confirm-dialog-message,
.export-toaster-success .p-confirm-dialog-message {
  margin-left: 10px !important;
}
.export-toaster .p-dialog-header,
.export-toaster .p-dialog-footer,
.export-toaster-success .p-dialog-header,
.export-toaster-success .p-dialog-footer {
  display: none;
}
.export-toaster-info,
.export-toaster-success {
  border-radius: 9px;
}
.p-datepicker-calendar th {
  text-align: center !important;
}
.sponsoredChild-check .p-highlight {
  background: transparent !important;
  border-color: #999 !important;
}
.excel-toast {
  width: 19rem !important;
}
.excel-toast .p-toast-detail {
  margin-top: 5px !important;
}
.view-tool .wv-input-bg,
#overlay_panel .wv-input-bg {
  padding-left: 10px;
  padding-right: 10px;
  background: white;
  border-radius: 5px;
  color: black;
  align-self: center;
  text-align: center;
}

.source-label {
  font-weight: bold;
  color: rgba(102, 102, 102, 1) !important;
}
.pc-confirmation-popup {
  z-index: 9999 !important;
}
.pc-confirmation-popup-bg {
  z-index: 9998 !important;
}

.rcds-close-btn {
  background: #666666 !important;
  color: white !important;
  border: none !important;
}
.rcds-close-btn:active,
.rcds-close-btn:focus {
  box-shadow: 0 0 0 0.2rem #666666 !important;
}
.rcds-popup label {
  display: flex;
  align-items: center;
  grid-gap: 16px;
  gap: 16px;
}
.rcds-popup label span {
  font-size: 2rem;
}
.rcds-popup button:hover,
.rcds-popup button:active,
.rcds-popup button:focus {
  box-shadow: none !important;
}


.ads-link {
  padding-left: 12px !important;
}
@media only screen and (min-width: 300px) and (max-width: 1000px) {
  .header-split {
    margin-top: 5%;
  }
  .view-fund-header{
    padding-top:5%
  }

}
.policy-pdf canvas{
  margin: auto;
}
@media only screen and (min-width: 320px) and (max-width: 600px) {
  .policy-pdf canvas{
    width: 100% !important;
  }
}
.count-info {
  color: #777777;
  font-weight: bold;
  padding-top: 5px;
  padding-bottom: 5px;
  font-size: 16px;
  display: flex;
  align-items: center;
  grid-gap: 4px;
}
.count-info .pi-exclamation-triangle {
  color: #00accb !important;
}
.wv-collapse .p-accordion-header-link{
  background-color: #96d8cd6b !important;
  padding-top: 8px;
  padding-bottom: 8px;
  border: 2px solid white !important;
  border-radius: 4px;
}
.info-field-msg{
  color: #00accb !important;
  font-weight: 500;
  font-size: 12px ;
  font-family: 'Lato', sans-serif;
}
.info-field-msg span{
  color: #00accb !important;
  font-weight: 500;
  font-size: 11px;
}
.info-err-border,
.info-err-border:focus,
.info-err-border:hover,
.info-err-border.p-inputtext:enabled:focus {
  border: 1.3px solid #00accb !important;
}
.info-field-msg.res-exp{
  font-size: 14px !important;
}
.p-sidebar {
  background: #2a2a2a !important;
}

.p-sidebar .p-sidebar-header {
  padding: 0px !important;
}

.p-sidebar .p-sidebar-content {
  padding: 0px !important;
}

.p-panelmenu .p-panelmenu-content .p-menuitem .p-menuitem-link .p-menuitem-text {
  font-size: 16px;
  font-weight: 400;
  padding: 13px 20px !important;
  border-right: 4px solid transparent;
}

.p-component .p-panelmenu-header .p-highlight {
  border: none;
}

.p-menuitem .p-menuitem-link {
  padding: 4px 6px !important;
  border-bottom: 1px solid #fff !important;
}

.p-panelmenu .p-menuitem-text {
  font-size: 16px;
  font-weight: 400;
}

.module-title {
  margin: 37px 20px 33px;
}

.text-white {
  vertical-align: middle;
  font-size: 24px;
  margin-left: 30px;
  color: #ffffff;
  font-weight: 100;
}

.close {
  float: none;
  height: 14px;
  width: 14px;
  display: inline-block;
}

.user {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  position: relative;
}

.userName {
  font-family: 'Lato', sans-serif;
  font-size: 20px;
  font-weight: bold;
  color: black;
}

.country {
  font-family: 'Lato', sans-serif;
  font-size: 12px;
  font-weight: 400;
  overflow: hidden;
  color: black;
  white-space: nowrap;
  text-overflow: ellipsis;
  margin-bottom: 0;
  position: relative;
  top: 2px;
}

.userNameSpace {
  width: 100px;
  display: inline-block;
  padding-left: 10px;
}

.profile-icon {
  display: inline-block;
  height: 36px;
  width: 36px;
  top: 4px;
  left: 7px;
  border: 1px solid #fff;
  border-radius: 50%;
  box-shadow: 0px 0px 4px 1px #a7a4a4;
  background: #fd8127;
}
.wv-menuPanel .pi-chevron-down {
  color: #fd8127;
}
.wv-menuPanel .p-panelmenu-header-link:focus {
  box-shadow: none !important;
}
.wv-menuPanel .p-highlight .p-menuitem-text {
  font-weight: bold !important;
}
.profile-icon img {
  position: absolute;
  left: 5px;
  top: 6px;
}
.wv-menuPanel .p-menuitem-link:hover {
  border-left: 4px solid rgba(243, 112, 33, 1) !important;
}
.wv-menuPanel .p-menuitem-link:focus {
  box-shadow: none !important;
}
.divider {
  display: inline-block;
  border-left: 1px solid #fff;
  height: 35px;
}

.live-icon {
  position: absolute;
  right: 0;
  bottom: 0;
  height: 12px;
  width: 12px;
  background: #2bee4c;
  border-radius: 50%;
}

.menualign {
  font-size: 11px;
  color: #fff;
  font-family: 'Lato', sans-serif;
  border-bottom: 1px solid rgba(255, 255, 255, 0.75);
  padding: 0 20px 20px 20px;
}
#mv-drawer .offcanvas-body {
  border-right: 4px solid #dee2e6;
  overflow-x: hidden;
}
#mv-drawer {
  padding: 0px !important;
  width: 20%;
}
.setting {
  margin-top: -15px;
}
.wv-menuPanel .p-panelmenu-header-link {
  display: flex;
  grid-gap: 15px;
  gap: 15px;
  background: transparent !important;
  border-top: 0px !important;
  border-radius: 0px !important;
}
.wv-menuPanel .p-panelmenu-header-link .p-panelmenu-icon {
  order: 1;
}
.wv-menuPanel .p-panelmenu-header-link .p-menuitem-text {
  width: 90%;
  font-weight: 400;
}
.wv-menuPanel .p-panelmenu-content {
  padding-bottom: 0px;
  padding-top: 0px;
}
.active-li {
  border-left: 4px solid rgba(243, 112, 33, 1) !important;
  background: #e9ecef;
}
.wv-menuPanel .active-li .p-menuitem-text,
.p-menuitem-link:hover .p-menuitem-text {
  border-right-width: 0px !important;
}
@media only screen and (min-width: 320px) and (max-width: 480px) {
  #mv-drawer {
    padding: 0px !important;
    width: 100%;
  }
}
@media only screen and (min-width: 480px) and (max-width: 786px) {
  #mv-drawer {
    padding: 0px !important;
    width: 50%;
  }
}
@media only screen and (min-width: 786px) and (max-width: 1024px) {
  #mv-drawer {
    padding: 0px !important;
    width: 40%;
  }
}
.nav-border {
  border-right: 1px solid #999999;
  height: 25px;
}
.country-item {
  display: flex;
  grid-gap: 20px;
}
.header-language-div {
  grid-gap: 2px;
  gap: 2px;
  border-right: 1px solid #999999;
  height: 25px;
}
.header-img2 {
  pointer-events: none;
}
.header-language-div img {
  margin-top: -10px;
}
.nav-country-list {
  position: absolute;
  z-index: 1000;
}
.nav-country-list input {
  width: 100%;
}
.nav-border button {
  padding-top: 0px !important;
  margin-top: -3px;
}
#wv-logo {
  margin-top: -8px;
}
.wv-grow {
  flex-grow: 0.94;
  padding: 13px !important;
  align-items: self-end;
  display: flex;
  padding-left: 0px !important;
}
.menu-btn-div {
  width: 4.2%;
  display: flex;
  justify-content: flex-end;
}
#header_img3,
#wv-logo {
  width: 7.5rem;
  cursor: pointer;
}
#header_img1 {
  width: 22px;
}
.p-overlaypanel .p-overlaypanel-content {
  padding: 0px !important;
}
.wv-header {
  box-shadow: 0px 0px 8px -4px #c5c2c2;
  position: fixed;
  width: 100%;
  z-index: 9;
  background-color: #f5f5f5;
  margin-left: 0px;
  padding-top: 3px;
}
.wv-header .p-speeddial {
  display: none;
}
#help_bg {
  background-image: url(/static/media/Help_header.a29af581.svg);
}
#help_bg:active,
#help_bg:hover {
  background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABgAAAAYCAYAAADgdz34AAAABHNCSVQICAgIfAhkiAAAAnxJREFUSEuVVtt1GjEQnXGSv4RHB4v/fUwqACoIriA4DUSpwE4F3goSUkG8FXhdgc3xP2wHxjh/JFbuaCUQu9IadM4eYGc0984daQamhvWokuQd0yetj4ZEOoFr37rfE3HB/JKvNWXdtChiYThkMIHp6EKTnlj7Ez4RdGcJWFveMPF0TS/fQ0A1gJVKxtjwE/s6eG4BkrbS4jpExPoq2AZ4lvA9r/ruAGDDxAYHYz3+kBZ5k4TO9qwSSMhCom1Bps62AbBsfsMw+0t6iHSX+wR3PpC185ZYCJ0C5MxlYgBEcxjvRE4ET0LBS5ZGClm3oewsSAG7RpyPUhMDsFK9Kb58xvtRaOPWvs1JCvs+nZ9Xs7Ry3WiiX610MWHLflGyWgjLneVJl4GVFJSQrWg88KXwNz2rnkg1gH+P/6hEaeKrmLNjD+euk85K8ehYxkgx6W8MNKn+EOzlWNaWJSD2sTM6APzO/PeVLIBPmQDIBVqG5AkB+jWrHsmATG0BEKSg/iEAr+AzkHKto+bq6nAQgLuIMe1DhT5IIscKzIM9LADQcUUeYFM3prl7bwFon3pZ6bNXj2mA1asA7u6YY+pdtBzMRk1ZlAVmnLi5uXCxtXPR/GMXaxUu0Eodf5HvrXT+Ix7cdNZtqxBHm4XcB2lSvVgnBbMH8UemJyEAewGl7UjT7G+aXZmFGTTSru9hHIU76nFaAtQlssFvYO7X2vVWgs3AwSzQZwcOHCHXiQ4cD0QymeK3zNtcM0bmVZGFJLFZf4UNutOTzPDGkemCSE3eEF+WM2Kz8gqIBDVLbvY/0pd7DX0/SPnvgsZo5xIswXNq7TN8YlrpfE103fS35T9RBHFjSMPvyQAAAABJRU5ErkJggg==);
}
#home_bg {
  background-image: url(/static/media/Home.62f14ce6.svg);
}
#home_bg:active,
#home_bg:hover {
  background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABgAAAAYCAYAAADgdz34AAAABHNCSVQICAgIfAhkiAAAAblJREFUSEvtVt1RwkAQ3vPnDRAqMNgAdCB0ABUYOrhUIFbAWYGxAtKB2gE0AGcFIvimcn5LDkxC4KI+ys5kJnO3u9/ut3t7J6iAvEivekpHA0Omw+qCRPROy6Cm9MxlLlwKb9JrwuXQEHnQfbL6lzDURKZbUnq0z8degLn0Ooj2joNG9H5F6YidYd3HusKvwXpvvZ4HtBMAkUtDArTQ8xGoyUbKmS1BFRycC2FkaaBvCwPMZT2E4RUMxh9kWru45tqckHiEXgMZhSU16WVBUhlYgwcoNRH5fUVNfVeNYso2AY0QUDsZ0AaAUwYl7LwqyASghDkuLGtKYTCDfXtN6QrAFm2w6kDwXVaa0/6xLKTXggtuBC5+gOKHIoE8BrLvajsXqmUijOtiArGQdT4szF2nyMFxAfC+rWXEZ2erTbHpAUgXcZTVybNNAVi6rstqWvsNALMBWvrJBkkBLORFH/VhAOcIyQsAAGBF3JTVBH5iOQCkmDpQ5Ozcf0CRvSKHfxnXmKLd5BW6dWLBI1/iDXwY2abg2BYY08TfGFMAj4RvyRt21WNc6NjgJ8qZs7KxwitmRPRJRmYn8hcNDQ9/hpJHUQAAAABJRU5ErkJggg==);
}
.header-icons {
  width: 30px;
  height: 20px;
  background-repeat: no-repeat;
  background-size: 20px 19px;
  background-position: bottom;
  cursor: pointer;
}
#logout-bg {
  background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABkAAAATCAYAAABlcqYFAAAABHNCSVQICAgIfAhkiAAAAwVJREFUOE9j/P//v8Tu3btLz58/b/Xy5UsBBiKApKTkRysrqx1APIWRkfENIS2Mu3bt6q2srCw6e/YsIbUo8ubm5gw9PT0Ntra2jYQ0MnZ0dJyoqKgwP3HixJc7d+6cZGJiYsSn6fv3738UFBQcnZ2dWadNm3Y4MzMzBOibV/j0MBYWFt7o7+9XX7Jkyd7o6Gg/oOLfBFzGvWDBglWJiYmuwBC41dbW5gC05Dkw2C1B+oDs4+j64ZYsW7ZsX2RkpAdQEV5LgIaBLFmPbAnQUO2kpKQ5SkpKrLGxsWVAny5FtogcS9SA8bjI3d3dfO7cubeBhic+fPhQvr6+funChQsZgPwHdXV11UCLlsEsItkSkMYHDx5EHTt2LFlDQ2ODkZHRZKDvDFetWlU0ceLEGKA4Q3Jy8oOampoqRUXF5eAghMUJruACGiADVMdMKAUB5fl37NjRMGfOnMC1a9cypKamPqyqqioHWrQSryVACzSAlk89d+6cFBGWMHBxcTE/f/5cGmgRF0h9Tk7Oo8mTJ8cQssQdmA92HDlyhBg7MNTo6+szlJaW1hCyRHr79u1NINdxcHCwErKJj4+P8/jx43LAZC3Nw8PDkJub+xTITiQYJyCDgcHGhmYBC5D/B4i5gfgrVE5h586dneXl5QF3795lyMjIeAJMaRlaWlpbibIE2QKghYyHDx+u3bRpU1RYWNg6U1PTFqC8xdatWzPLyspCHj16hGIBUakLPYhAmREYzrOA5VYUsIS4Aiwp3ID5xAkYNEtAcQdMvnAfkJ1PcOR4RWARUy0CBD4+Pq3A/LMJa47v7u7eWFJSUggNa3xxzNXa2joZmNlcgXnsZl9fnyO07AIncyD7GbpmxgkTJpwqKCgw9ff3/6qqqvqEUAoCyRsaGioCg4pt8eLFe2NiYsKABr/Dp48RWFnlA73aumfPHu4/f0AJhjBgY2NjcHNz+zp16tRseXn5hYR0MALDmB2YIkKBuTrqy5cv6EkVQ/+vX7/+CggI/AGWWUuAheBKoC/+EbIEACJDuAYoYe+nAAAAAElFTkSuQmCC);
  background-size: 22px 17px;
}
#logout-bg:hover,
#logout-bg:active {
  background-image: url(/static/media/logout_orange.82875c55.svg);
  background-size: 22px 18px;
}
.header-logout {
  width: 30%;
}
@media screen and (min-width: 1200px) {
  .p-xl-4 {
    width: 31.3333%;
  }
  .icons-div {
    width: 10.3333%;
  }
}

@media only screen and (min-width: 300px) and (max-width: 768px) {
  .wv-grow {
    flex-grow: 1;
  }
  .header-language-div {
    grid-gap: unset !important;
    gap: unset !important;
    cursor: pointer;
    border-right: none;
    justify-content: start;
  }
  .nav-border {
    border-right: none;
  }
  .wv-grow + div {
    padding-left: 0px;
    padding-right: 0px;
  }
  .menu-btn-div {
    width: 11%;
    display: block;
    justify-content: end;
  }
  .icons-div,
  .logo-div {
    display: none;
  }
  #overlay_panel_language {
    left: 20vw !important;
    --overlayArrowLeft: 54vw !important;
  }
  .wv-header .p-speeddial {
    display: block;
    top: 0;
    right: -8px;
  }
  .col-sm-header .p-speeddial button {
    width: unset;
    height: unset;
    background: transparent;
  }
  .col-sm-header {
    width: 40%;
  }
  .col-sm-header button:focus,
  .col-sm-header button:active {
    box-shadow: none;
    color: #f4803b !important;
    background-color: transparent !important;
  }
  .col-sm-header .pi-pencil::before {
    content: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAUCAYAAACNiR0NAAAABHNCSVQICAgIfAhkiAAAAP5JREFUOE/tlNENgjAURe0GuoEbqBuwhv6IE4gTyAiwAf7oGo6AE+gGugGeawqpSKnhz8QmNyXte6el77ZmFGhVVSWE7NEDZcaYvC/F9E0CK5hfo4uNm9EXQDe+PC/QgR0AxAI4Y15oJ7ALVu8oBP0A9sG+gb4Bv4GFoA3QgZ04s1Wo+vZMj/RLt1AvYKuaZV2EENTmzYlrqm8YzBjYIvlLXosARiGY3ciZXppae+UCCpIAKfhOhwDJTW1u0i7KYGD9R3/g8KL87hnGbF2P6QJvyZ/ehu/GTF7RTh72/bKCbuiOmiAPVYtPdEvcxbueL91NwXQ/+5pe8RhY6QY9AY2wvI89C8JtAAAAAElFTkSuQmCC);
  }
  .col-sm-header .pi-refresh::before {
    content: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAUCAYAAACNiR0NAAAABHNCSVQICAgIfAhkiAAAAdpJREFUOE+NlM1Nw0AQheMOoAIciROXJBWQDuJ0ECTuJBUAFQB3JEIFhAoIFSS5cCWIBuDIybzP7JrZiY1YaeT17Js3v7tZp2WVZTnR0bGkHwTkOsgyy7L7JtPMK0VUSHclySUbycJhOO9JtpKZiJPzhFBk1wKdSR4lU4Ex2lnC4QzsiK9wswiqCQ3ZiQDzCJB+L0SEaqOzD3M20f5OciP9FH1FGNJ80NaTYUD6kLIgI03rMJKOST8SbgVcS0F9qiUnNGMleZNcBFKiOJAMhKVBEUsde9J1s9BNwu7ampkS1MbGSZ1icJ7r+yoZQ0j4fZERUb2Cfig9YKsv9fMs/dDpq5GCkM1CANJKFg1xTSBlakod6bJ1hP0IQjxeNhE6g4n+Kc2nJLeOwIkGwvN/EQpMek8SBr1omk9L2JpyjFBgusgQ7/vIDIYIi9gUWj7wNTTgpfbUM2mcK0ndlFibZGwcGMKO76xxmGv/MzahoFt9VzIYN0WplN+l/9L5Ycs5JWH08j+vnongJUR45AnNxfi9eiHK+NIk9zmcnQbCW1eKWK70cTCRRFJSYHgpxc4KzxcDzt1PrmHbAwsxj0A1Uo4RErrNo8Gb2f7AunQwjMa80CwGO86td1QBvgFJGgmJO0Z9PAAAAABJRU5ErkJggg==);
  }
  .col-sm-header .pi-trash::before {
    content: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABUAAAAQCAYAAAD52jQlAAAABHNCSVQICAgIfAhkiAAAAYpJREFUOE+Vk0soBVEch2eklEcWxI6lLCQJCxaUPLJQVpTHloWV3NJNNsrCtbhLG3spiSLdothYsJBE3IWUBQtKXiHj++ncOt3MYaa+Zub/+ObMf874QRDUe543C9XwCmHHJ4lbSPq+v+Go83ykWxR0uYqycnvcDyG+DuuR9IpkJezArkNeR64P7qET6SG9Zarn+s7uk/ScQBVMk9QYfj2oayGxb5INnDUO1Us8SO9FpjGqNEWj5t4B+bAKJbAN44gvf1YeYaVaUQyeIAnFMGxiBZzXYBJxOlTKwwopGoWisJEQz4E2aDY1Esdd0h4KlkGriHLEXFJ9DL1m6R/GPPIVpuaFc79zpubHuLGkNVx/wamJ6X4eakHCGWaaiPKhGmlahDcYgwDmoNsW6mFRpNn7VK+9Dtpa2uOJzBvZ0gWCoZufXDusmEbN+wh64R3hpj13SQ8INMEJ2POz63RdDvpVz2AA0XF2gb3SODdT8J+t80zdEkwg/XBJc0m2wsgfK30kn4YUwocwoeLfnr+k9M/HEEQAAAAASUVORK5CYII=);
  }
  .col-sm-header .p-speeddial-action {
    background: #717375;
    width: 45px;
    height: 45px;
  }
}
@media only screen and (min-width: 768px) and (max-width: 1024px) {
  .wv-grow {
    flex-grow: 0.5;
  }
  .header-language-div {
    border-right: 1px solid #999999;
  }
  .nav-border {
    border-left: none;
  }
  .menu-btn-div {
    width: 7%;
    display: block;
    justify-content: flex-end;
  }
}
.header-logout .p-confirm-dialog-accept {
  height: 36px;
}

.user-access-form p {
  text-align: left;
  margin-bottom: 0px;
}
.userOffice-lbl {
  font-family: 'Lato', sans-serif;
  color: #666666;
}
.user-access-form .p-dropdown,
.user-access-form .p-multiselect {
  float: left;
  width: 100% !important;
  height: 42px !important;
  margin-top: 0px !important;
  border: 1px solid #999999 !important;
}
.user-access-form .p-multiselect-label {
  padding: 0px;
}
.user-access-form .p-dropdown-label {
  line-height: 18px;
}
.line-break {
  position: absolute;
  top: 50px;
}
.user-access-form .p-placeholder {
  text-align: left;
  padding-top: 2%;
  padding-left: 5px;
}
@media only screen and (min-width: 300px) and (max-width: 500px) {
  .user-access-form .p-dropdown,
  .user-access-form .p-multiselect {
    width: 98%;
  }
  .form-mobile-mr {
    margin-top: 4% !important;
    display: flex;
    justify-content: flex-end;
  }
}

.grid-footer-btn {
  display: flex;
  justify-content: flex-end;
  grid-gap: 10px;
  gap: 10px;
}
.disabled {
  pointer-events: none;
}
.us-mobile-div {
  padding-top: 4.5%;
}
.form-mobile-mr {
  margin-top: 20px;
}
.assign-dropdown {
  width: 100% !important;
}
#dropdown-div {
  width: 22%;
}
.user-access-content {
  padding: 1rem;
}
@media only screen and (min-width: 300px) and (max-width: 500px) {
  #dropdown-div {
    width: 100%;
  }
  .us-mobile-div {
    padding-top: 16%;
  }
  .user-access-content {
    padding: 1rem !important;
  }
  .us-mobile-div .ag-mobile-view,
  .assign-role-div .ag-mobile-view {
    padding-left: 0px;
    padding-right: 0px;
  }
  .us-main-content {
    padding: 2%;
    padding-top: 31%;
  }
}

#outModal {
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  background: #000000a8;
  z-index: 999;
  height: 100vh;
}

#outModal_inner {
  background-color: white;
  color: black;
  position: absolute;
  top: 3vh;
  right: 35vw;
  padding: 1.6%;
  border-radius: 5px;
  width: 400px;
  padding-bottom: 1.4%;
}

#outModal_inner .pi-exclamation-triangle {
  font-size: 28px;
}

#outModal_inner div {
  display: flex;
}

@media only screen and (min-width: 300px) and (max-width: 500px) {
  #outModal_inner {
    background-color: white;
    color: black;
    position: absolute;
    top: 3vh;
    right: 3vw;
    padding: 2%;
    border-radius: 5px;
    width: 354px;
  }
}

.legal-popup .p-dialog-footer {
  padding: 15px;
}
.legal-popup .p-dialog-content {
  border-top: 1px solid #9999;
  border-bottom: 1px solid #9999;
  padding: 0;
}
.legal-popup p {
  text-align: justify;
}
.legal-popup ul {
  list-style-type: square;
}
.legal-popup .p-dialog-content p:nth-child(1) {
  margin-top: 10px;
}

.legal-popup .p-dialog-content > div {
  justify-content: center;
  height: 100%;
  align-items: center;
}

.idle-tool-tip-class {
  font-size: 12px;
}
.idleModalFooter {
  text-align: center !important;
}

.land-image-container {
  background-image: url(/static/media/landingPageBG.d2f74ce2.png);
  background-repeat: no-repeat;
  height: 97vh;
  background-size: cover;
}
.land-container {
  padding-top: 4%;
}
.land-bottom-container {
  background-color: rgb(0 0 0 / 95%);
  opacity: 0.8;
  color: white;
  width: 40%;
  padding: 20px;
  position: absolute;
  bottom: 8%;
  right: 4%;
  text-align: left;
  font-family: 'Lato', sans-serif;
  border-radius: 4px;
}
.pr-heading {
  font-size: 20px;
  font-weight: bold;
}
.pr-heading + p {
  word-spacing: 0.8px;
}
@media only screen and (min-width: 300px) and (max-width: 500px) {
  .land-bottom-container {
    width: unset;
    right: unset;
  }
  .land-image-container {
    height: 98vh;
    background-size: cover;
  }
}
@media only screen and (min-width: 1200px) {
  .land-image-container {
    height: 91.5vh;
  }
}
.land-container.fullscreen{
  padding: 0;
}
.land-container.fullscreen .land-image-container{
  height: 100vh;
}


.adDiv {
  padding-top: 30vh;
  text-align: center;
}
.adImage {
  margin-top: 1rem;
}
.pcrm_heading {
  font-family: 'Lato', sans-serif;
  color: #666666;
  font-weight: bold;
  font-size: 1.4rem;
}

.pcrmErrorMessage {
  margin-bottom: 0px !important;
  color: rgba(51, 51, 51, 1);
}
/* body{
  overflow: hidden;
} */

