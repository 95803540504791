.land-image-container {
  background-image: url('../../../../assets/images/landingPageBG.png');
  background-repeat: no-repeat;
  height: 97vh;
  background-size: cover;
}
.land-container {
  padding-top: 4%;
}
.land-bottom-container {
  background-color: rgb(0 0 0 / 95%);
  opacity: 0.8;
  color: white;
  width: 40%;
  padding: 20px;
  position: absolute;
  bottom: 8%;
  right: 4%;
  text-align: left;
  font-family: 'Lato', sans-serif;
  border-radius: 4px;
}
.pr-heading {
  font-size: 20px;
  font-weight: bold;
}
.pr-heading + p {
  word-spacing: 0.8px;
}
@media only screen and (min-width: 300px) and (max-width: 500px) {
  .land-bottom-container {
    width: unset;
    right: unset;
  }
  .land-image-container {
    height: 98vh;
    background-size: cover;
  }
}
@media only screen and (min-width: 1200px) {
  .land-image-container {
    height: 91.5vh;
  }
}
.land-container.fullscreen{
  padding: 0;
}
.land-container.fullscreen .land-image-container{
  height: 100vh;
}
