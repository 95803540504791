
.adDiv {
  padding-top: 30vh;
  text-align: center;
}
.adImage {
  margin-top: 1rem;
}
.pcrm_heading {
  font-family: 'Lato', sans-serif;
  color: #666666;
  font-weight: bold;
  font-size: 1.4rem;
}

.pcrmErrorMessage {
  margin-bottom: 0px !important;
  color: rgba(51, 51, 51, 1);
}
/* body{
  overflow: hidden;
} */
