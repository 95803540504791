.p-sidebar {
  background: #2a2a2a !important;
}

.p-sidebar .p-sidebar-header {
  padding: 0px !important;
}

.p-sidebar .p-sidebar-content {
  padding: 0px !important;
}

.p-panelmenu .p-panelmenu-content .p-menuitem .p-menuitem-link .p-menuitem-text {
  font-size: 16px;
  font-weight: 400;
  padding: 13px 20px !important;
  border-right: 4px solid transparent;
}

.p-component .p-panelmenu-header .p-highlight {
  border: none;
}

.p-menuitem .p-menuitem-link {
  padding: 4px 6px !important;
  border-bottom: 1px solid #fff !important;
}

.p-panelmenu .p-menuitem-text {
  font-size: 16px;
  font-weight: 400;
}

.module-title {
  margin: 37px 20px 33px;
}

.text-white {
  vertical-align: middle;
  font-size: 24px;
  margin-left: 30px;
  color: #ffffff;
  font-weight: 100;
}

.close {
  float: none;
  height: 14px;
  width: 14px;
  display: inline-block;
}

.user {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  position: relative;
}

.userName {
  font-family: 'Lato', sans-serif;
  font-size: 20px;
  font-weight: bold;
  color: black;
}

.country {
  font-family: 'Lato', sans-serif;
  font-size: 12px;
  font-weight: 400;
  overflow: hidden;
  color: black;
  white-space: nowrap;
  text-overflow: ellipsis;
  margin-bottom: 0;
  position: relative;
  top: 2px;
}

.userNameSpace {
  width: 100px;
  display: inline-block;
  padding-left: 10px;
}

.profile-icon {
  display: inline-block;
  height: 36px;
  width: 36px;
  top: 4px;
  left: 7px;
  border: 1px solid #fff;
  -moz-border-radius: 50%;
  -webkit-border-radius: 50%;
  border-radius: 50%;
  box-shadow: 0px 0px 4px 1px #a7a4a4;
  background: #fd8127;
}
.wv-menuPanel .pi-chevron-down {
  color: #fd8127;
}
.wv-menuPanel .p-panelmenu-header-link:focus {
  box-shadow: none !important;
}
.wv-menuPanel .p-highlight .p-menuitem-text {
  font-weight: bold !important;
}
.profile-icon img {
  position: absolute;
  left: 5px;
  top: 6px;
}
.wv-menuPanel .p-menuitem-link:hover {
  border-left: 4px solid rgba(243, 112, 33, 1) !important;
}
.wv-menuPanel .p-menuitem-link:focus {
  box-shadow: none !important;
}
.divider {
  display: inline-block;
  border-left: 1px solid #fff;
  height: 35px;
}

.live-icon {
  position: absolute;
  right: 0;
  bottom: 0;
  height: 12px;
  width: 12px;
  background: #2bee4c;
  -moz-border-radius: 50%;
  -webkit-border-radius: 50%;
  border-radius: 50%;
}

.menualign {
  font-size: 11px;
  color: #fff;
  font-family: 'Lato', sans-serif;
  border-bottom: 1px solid rgba(255, 255, 255, 0.75);
  padding: 0 20px 20px 20px;
}
#mv-drawer .offcanvas-body {
  border-right: 4px solid #dee2e6;
  overflow-x: hidden;
}
#mv-drawer {
  padding: 0px !important;
  width: 20%;
}
.setting {
  margin-top: -15px;
}
.wv-menuPanel .p-panelmenu-header-link {
  display: flex;
  gap: 15px;
  background: transparent !important;
  border-top: 0px !important;
  border-radius: 0px !important;
}
.wv-menuPanel .p-panelmenu-header-link .p-panelmenu-icon {
  order: 1;
}
.wv-menuPanel .p-panelmenu-header-link .p-menuitem-text {
  width: 90%;
  font-weight: 400;
}
.wv-menuPanel .p-panelmenu-content {
  padding-bottom: 0px;
  padding-top: 0px;
}
.active-li {
  border-left: 4px solid rgba(243, 112, 33, 1) !important;
  background: #e9ecef;
}
.wv-menuPanel .active-li .p-menuitem-text,
.p-menuitem-link:hover .p-menuitem-text {
  border-right-width: 0px !important;
}
@media only screen and (min-width: 320px) and (max-width: 480px) {
  #mv-drawer {
    padding: 0px !important;
    width: 100%;
  }
}
@media only screen and (min-width: 480px) and (max-width: 786px) {
  #mv-drawer {
    padding: 0px !important;
    width: 50%;
  }
}
@media only screen and (min-width: 786px) and (max-width: 1024px) {
  #mv-drawer {
    padding: 0px !important;
    width: 40%;
  }
}