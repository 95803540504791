@import url('https://fonts.googleapis.com/css2?family=Lato:wght@100;400&display=swap');

.App {
  text-align: center;
  min-height: 100vh;
  height: 100vh;
}

* {
  font-family: 'Lato', sans-serif;
}

html {
  font-family: 'Lato', sans-serif;
  color: #666666;
  font-size: 16px;
  scroll-behavior: smooth;
}

.wv-bordered-div label:not(.tooltip-button),
.rcds-popup label {
  font-family: 'Lato', sans-serif;
  color: #666666;
  font-size: 16px;
}

::-webkit-input-placeholder {
  font-family: 'Lato', sans-serif;
  color: #898686;
}

:-ms-input-placeholder {
  font-family: 'Lato', sans-serif;
  color: #898686;
}

input::placeholder,
.p-multiselect-label {
  font-family: 'Lato', sans-serif;
  color: #898686;
}

.p-dropdown-label {
  font-family: 'Lato', sans-serif !important;
  color: #898686 !important;
}

.p-inputwrapper-filled .p-dropdown-label {
  color: #495057 !important;
}

.p-inputtext,
.p-dropdown,
.p-datepicker-trigger {
  height: 40px;
}

.p-dropdown,
.p-datepicker-trigger {
  border: 1px solid #999999 !important;
}

.p-dropdown-panel .p-dropdown-items .p-dropdown-item.p-highlight,
.p-multiselect-panel .p-multiselect-items .p-multiselect-item.p-highlight {
  background: none !important;
}

.App-header {
  height: 15vh;
  display: flex;
  flex-direction: row;
  font-size: calc(10px + 2vmin);
  background: #fff;
  padding-top: 15px;
  width: 100%;
  z-index: 1111;

}

#header_btn1 {
  background: transparent;
  border: none;
}



.p-dropdown span {
  line-height: 23px;
  text-align: left;
}

.fieldStyle {
  font-size: medium !important;
}

input,textarea {
  width: 63%;
  border: 1px solid #999999 !important;
}

.p-calendar input {
  padding: 5px;
}

.p-checkbox:not(.p-checkbox-disabled) .p-checkbox-box.p-highlight:hover {
  border-color: #fe6b01 !important;
  background: #fe6b01 ip !important;
  color: #ffffff;
}

.p-checkbox .p-checkbox-box.p-highlight:not(.sponsoredChild-check .p-highlight) {
  border-color: #fe6b01 !important;
  background: #fe6b01 !important;
}

.form-check-input:checked {
  background-color: white !important;
  border-color: #fe6b01 !important;
}

.p-checkbox:not(.p-checkbox-disabled) .p-checkbox-box:hover {
  border-color: #fe6b01 !important;
}

.basecontainer {
  width: 95%;
  margin: auto;
  padding-top: 55px;
}

.main-content-align {
  width: 97%;
  margin: auto;
  padding-top: 22px;
}

.err-msg,
.mand-field-msg {
  margin-bottom: 0px;
  text-align: left;
  color: #cc1d00 !important;
  font-family: 'Lato', sans-serif;
  font-size: 11px;
  font-weight: 600;
}
.err-msg span,
.mand-field-msg span {
  font-size: 11px;
  font-weight: 600;
}
.csm-header {
  display: flex;
  align-items: unset;
  grid-gap: 10px;
}

.csm-header .pi-angle-left {
  font-size: 1.5rem;
  color: gray;
  line-height: 32px;
  cursor: pointer;
  padding-top: -1px;
}


.header-split .navbar-brand,
.mobile-us-header .navbar-brand,
.us-mobile-div .navbar-brand {
  font-weight: bold !important;
}

.wv-header-div {
  display: flex;
  align-items: unset;
  grid-gap: 10px;
}

.wv-header-div .pi-angle-left,
.header-split .pi-angle-left {
  font-size: 1.5rem;
  color: gray;
  line-height: 32px;
  cursor: pointer;
  padding-top: -1px;
}

.header-split button:first-child {
  margin-left: -5px;
  justify-content: center;
}

.mand-field {
  color: #cc1d00 !important;
  font-family: 'Lato', sans-serif;
  font-size: 18px;
}

.disable {
  pointer-events: none;
  opacity: 0.7;
}

.custom-switch-aggrid input {
  width: 40px !important;
  height: 20px;
}

a {
  text-decoration: none !important;
  color: #00accb;
  cursor: pointer;
}
.table-link {
  color: #00accb !important;
}
.title-cl {
  color: #7d7a7a;
}

.popup-wd {
  width: 32%;
}

.header-split {
  display: flex;
  justify-content: space-between;
  align-items: baseline;
  margin-top: 3%;
}
.pcrmHeaderSplit {
  display: flex;
  justify-content: space-between;
  align-items: baseline;
  margin-top: 0%;
}
.pcrmHeaderSplit .navbar-brand,
.mobile-us-header .navbar-brand,
.us-mobile-div .navbar-brand {
  font-weight: bold !important;
}

.cl-light {
  color: #898787;
}

.wv-collapsible a {
  background: #acdbcc !important;
  border: 2px solid white !important;
}

a:focus {
  box-shadow: none !important;
}

.p-disabled:not(.p-button, .p-datepicker-calendar .p-disabled, .p-inputswitch) {
  background: #cfcdcd6e !important;
}

.p-datepicker-trigger:focus,
.p-datepicker-trigger:hover {
  background-color: transparent !important;
  box-shadow: none !important;
  border: 1px solid #999 !important;
}

.wv-bordered-div {
  border: 1px solid #999;
  border-radius: 6px;
}

.p-checkbox .p-checkbox-box {
  border: 1px solid #999 !important;
}

.ag-header-container,
.ag-pinned-right-header,
.ag-header-viewport {
  background: #daf1ea !important;
  height: 50px !important;
}

.ag-header-row-column {
  background: #daf1ea !important;
}

.ag-header-container .ag-header-cell-text,
.ag-pinned-right-header .ag-header-cell-text,
.ag-header-viewport .ag-header-cell-text,
.ag-header-row-column {
  color: #333333 !important;
}

.mand-field-para {
  color: #898686;
}

input {
  font-family: inherit;
}

.hidden {
  display: none !important;
}

.p-button.p-button-warning,
.p-confirm-dialog-accept {
  background: #ff6b00 !important;
  color: white !important;
}

.wv-light-btn {
  background: #00accb !important;
}

.p-confirm-dialog-accept {
  border: none !important;
}

.p-button-secondary:not(.table-icon) {
  color: #333333 !important;
}

.wv-cancel-btn,
.p-confirm-dialog-reject {
  background: #ccc !important;
  color: black !important;
  border: 1px solid #cccccc !important;
}

.wv-cancel-dark-btn {
  background-color: #666666 !important;
}

.wv-cancel-dark-btn span {
  color: white !important;
}

.excel-icon {
  background-repeat: no-repeat !important;
  background-position: 10% !important;
}



.p-datepicker-trigger {
  border: 1px solid #999 !important;
  background: white !important;
  border-left: none;
}

.darker-divider.p-divider.p-divider-horizontal:before {
  border-top: 1px solid #999 !important;
}

.pi-calendar {
  color: grey;
}

.p-calendar input {
  border-right: none !important;
}

.pi-spin {
  animation: fa-spin 1s infinite ease-out !important;
}

.pi-spinner {
  color: #ff6b00 !important;
  font-size: 40px;
}

.wv-lbl-light {
  color: rgba(102, 102, 102, 1) !important;
}

.age-grid {
  margin-top: -28px !important;
  padding: 4px;
  border: 1px solid #999;
  margin-right: 0% !important;
  border-radius: 3px;
}

.age-grid input {
  width: 90%;
}

.locked-col {
  background: #d3d1d1cf;
  border: none;
  border-left: 13px solid white !important;
  border-right: 16px solid white !important;
}

.wv-table table thead tr th {
  background: #cee5de !important;
}

.p-radiobutton .p-radiobutton-box.p-highlight {
  border-color: #fe7501 !important;
  background: #fe7501 !important;
}

.ag-side-buttons {
  display: none;
}

.transfer_child .ag-theme-balham .ag-checkbox-input-wrapper:active {
  box-shadow: none !important;
}

.transfer_child .ag-row .ag-cell-wrapper .ag-selection-checkbox .ag-input-wrapper .ag-checked,
.transfer_child .ag-row .ag-cell-wrapper .ag-selection-checkbox .ag-input-wrapper {
  background-color: transparent;
  box-shadow: none !important;
}

.transfer_child .ag-row .ag-cell-wrapper .ag-selection-checkbox .ag-input-wrapper::after {
  content: '\25EF';
  font-size: 16px;
  left: 16px;
  top: -3px;
}

.transfer_child .ag-row .ag-cell-wrapper .ag-selection-checkbox .ag-checked::after {
  content: '\25C9' !important;
  font-size: 25px;
  left: 15px;
  top: -4px;
}

.project-div {
  padding-top: 4.5%;
}

.mobile-mand-para {
  display: none;
}



button span {
  font-family: 'Lato', sans-serif;
}

.p-multiselect-items li:hover,
.p-dropdown-items li:not(.p-dropdown-empty-message):hover,
.p-dropdown-items .p-highlight,
.p-multiselect-items .p-highlight {
  color: #fe7501 !important;
}

.ag-floating-bottom-container {
  text-align: left;
}

.sec-header {
  font-size: 1.1rem;
  margin-bottom: 0px !important;
  font-weight: bold;
}

.form-switch .form-check-input:checked,
.form-switch .form-check-input:focus {
  background-image: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'><circle r='3' fill='rgb(255,107,0)'/></svg>") !important;
}

.pi-exclamation-triangle {
  color: #d4422a;
}

.p-message.p-message-success {
  background: #b5e4d4 !important;
  border: none !important;
}

.p-message.p-message-error {
  border: none !important;
}

.p-message .p-message-wrapper {
  justify-content: center !important;
  display: inline-flex !important;
}

.mobile-wid .p-confirm-dialog-message {
  font-size: 18px;
}

@media only screen and (min-width: 300px) and (max-width: 540px) {
  .main-content-align {
    width: 95%;
    padding-top: 13%;
  }

  .basic-search-split {
    flex-direction: column;
    width: 94%;
  }

  .rowPadding {
    gap: 15px;
  }

  .bs-mobile-grid {
    flex-direction: column;
    width: 100%;
    margin-left: 0px;
    margin-right: 0px;
  }

  .bs-mobile-grid .p-calendar {
    width: 95% !important;
  }

  .res-mobile-grid {
    gap: 15px;
  }

  .popup-wd,
  .mobile-wid {
    width: 96% !important;
  }

  .mobile-wid .p-confirm-dialog-message {
    font-size: 20px;
  }

  .popup-wd .p-dialog-footer,
  .mobile-wid .p-dialog-footer {
    padding: 10px;
  }

  .mobile-table-scroll {
    overflow-x: scroll;
  }

  .project-div {
    padding-top: 12% !important;
  }

  .navbar-brand {
    font-weight: 500;
  }

  .mand-field-para {
    display: block;
    margin-bottom: 0px;
    width: 98%;
  }

  .lg-screen-mand {
    display: none;
  }

  .p-inputtext {
    width: 100% !important;
  }

  .p-calendar input {
    border-right: 1px solid #999 !important;
  }

  .please-note-div {
    width: 100% !important;
  }
  .please-note-div p {
    text-align: left !important;
  }
}

#center_loader {
  position: fixed;
  top: 9%;
  width: 100vw;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.4);
  transition-duration: 0.1s;
  z-index: 1101;
  left: 0;
}

#center_loader div:first-child,
#center_loader_app div:first-child {
  justify-content: center;
  position: absolute;
  top: 35%;
  right: 47.2vw;
}

.inner-scroll::-webkit-scrollbar,
.p-datatable-scrollable-body::-webkit-scrollbar,
:is(.p-picklist-source, .p-picklist-target, .legal-popup .p-dialog-content)::-webkit-scrollbar {
  width: 5px;
}

.inner-scroll::-webkit-scrollbar-track .p-datatable-scrollable-body::-webkit-scrollbar-track,
:is(.p-picklist-source, .p-picklist-target, .legal-popup
    .p-dialog-content)::-webkit-scrollbar-track {
  background: #f1f1f1;
}

.inner-scroll::-webkit-scrollbar-thumb,
.p-datatable-scrollable-body::-webkit-scrollbar-thumb,
:is(.p-picklist-source, .p-picklist-target, .legal-popup
    .p-dialog-content)::-webkit-scrollbar-thumb {
  background: rgba(136, 136, 136, 0.658);
  border-radius: 10px;
}


.please-note-div {
  text-align: initial;
  margin-left: auto;
}

.please-note-div p {
  color: #777777;
  line-height: 18px;
  font-size: 14px;
}

.please-note-div span {
  font-weight: bold;
}

.p-dialog-header {
  padding-top: 1rem !important;
  padding-bottom: 1rem !important;
}

.p-button-link {
  color: #00accb !important;
}

.p-dialog-header-close:focus,
.p-paginator-element:focus {
  box-shadow: none !important;
}

.wv-bordered-div.table-content {
  padding-left: 12px;
  padding-right: 12px;
}

.p-confirm-dialog .p-dialog-title,
.p-confirm-dialog .p-confirm-dialog-message {
  font-size: 16px !important;
}

.p-confirm-dialog .p-dialog-header {
  padding-bottom: 12px !important;
}

.p-confirm-dialog .p-dialog-footer {
  padding-bottom: 17px !important;
}

.p-confirm-dialog .p-dialog-content {
  padding-bottom: 20px !important;
}

.p-datatable tbody .p-row-odd {
  background-color: #f9f9f9 !important;
}

.p-datatable tbody tr {
  background: white !important;
}

.p-confirm-dialog-reject:active,
.p-confirm-dialog-reject:focus,
.p-confirm-dialog-accept:focus,
.p-confirm-dialog-accept:active {
  box-shadow: none !important;
}

.p-checkbox-box.p-focus {
  outline: none !important;
  box-shadow: none !important;
}

.p-button:not(.table-icon, .p-button-icon-only, .res-link, .ads-link) {
  padding-top: 5px !important;
  padding-bottom: 5px !important;
  padding-left: 25px !important;
  padding-right: 25px !important;
}
.p-datatable-scrollable-header-box {
  margin-right: 0px !important;
  margin-left: 0px;
}
::selection {
  background-color: #ff6b00 !important;
  color: white;
}
.p-multiselect-label .p-chip {
  border-radius: 4px;
}
.wv-collapsible .p-accordion-header-link {
  border-left-width: 0px !important;
  border-right-width: 0px !important;
}
.p-multiselect-items .p-multiselect-item {
  white-space: normal;
}
.tooltip-design-overlay {
  background-color: #333333e3 !important;
  color: white !important;
  padding: 5px;
  max-width: 25%;
  font-size: 14px !important;
  padding-top: 10px;
  padding-bottom: 10px;
}
.tooltip-design-overlay::before,
.tooltip-design-overlay::after {
  border-bottom-color: #00000063 !important;
}
.p-inputtext:enabled:focus {
  border: 1px solid #2196f3 !important;
  box-shadow: none !important;
}
.p-dropdown:not(.p-disabled).p-focus {
  outline: 0 none;
  outline-offset: 0;
  border-color: #2196f3 !important;
  box-shadow: none !important;
}

.p-dialog-footer {
  display: flex;
  justify-content: center;
}

.invalid-err-border,
.invalid-err-border:focus,
.invalid-err-border:hover,
.invalid-err-border.p-inputtext:enabled:focus {
  border: 1px solid #cc1d00 !important;
}
.invalid-err-border.date {
  border: none !important;
  outline: 1px solid #cc1d00 !important;
  border-radius: 2px;
}
.invalid-err-border.date input {
  border: none !important;
  border-right: 1px solid #cc1d00 !important;
}
.invalid-err-border.date button {
  border: none !important;
}
.p-dialog-bottom-left {
  background-color: transparent !important;
}
.export-toaster-info .p-dialog-content {
  background-color: #b3e5fc !important;
  border: solid #0891cf;
  border-width: 0 0 0 6px;
  color: #044868;
  padding: 10px 10px 10px 10px !important;
  border-radius: 6px;
}
.export-toaster-success .p-dialog-content {
  background-color: #b3e5fc !important;
  border: solid #0891cf;
  border-width: 0 0 0 6px;
  color: #044868;
  padding: 10px 10px 10px 10px !important;
  border-radius: 6px;
}
.export-toaster .p-confirm-dialog-message,
.export-toaster-success .p-confirm-dialog-message {
  margin-left: 10px !important;
}
.export-toaster .p-dialog-header,
.export-toaster .p-dialog-footer,
.export-toaster-success .p-dialog-header,
.export-toaster-success .p-dialog-footer {
  display: none;
}
.export-toaster-info,
.export-toaster-success {
  border-radius: 9px;
}
.p-datepicker-calendar th {
  text-align: center !important;
}
.sponsoredChild-check .p-highlight {
  background: transparent !important;
  border-color: #999 !important;
}
.excel-toast {
  width: 19rem !important;
}
.excel-toast .p-toast-detail {
  margin-top: 5px !important;
}
.view-tool .wv-input-bg,
#overlay_panel .wv-input-bg {
  padding-left: 10px;
  padding-right: 10px;
  background: white;
  border-radius: 5px;
  color: black;
  align-self: center;
  text-align: center;
}

.source-label {
  font-weight: bold;
  color: rgba(102, 102, 102, 1) !important;
}
.pc-confirmation-popup {
  z-index: 9999 !important;
}
.pc-confirmation-popup-bg {
  z-index: 9998 !important;
}

.rcds-close-btn {
  background: #666666 !important;
  color: white !important;
  border: none !important;
}
.rcds-close-btn:active,
.rcds-close-btn:focus {
  box-shadow: 0 0 0 0.2rem #666666 !important;
}
.rcds-popup label {
  display: flex;
  align-items: center;
  gap: 16px;
}
.rcds-popup label span {
  font-size: 2rem;
}
.rcds-popup button:hover,
.rcds-popup button:active,
.rcds-popup button:focus {
  box-shadow: none !important;
}


.ads-link {
  padding-left: 12px !important;
}
@media only screen and (min-width: 300px) and (max-width: 1000px) {
  .header-split {
    margin-top: 5%;
  }
  .view-fund-header{
    padding-top:5%
  }

}
.policy-pdf canvas{
  margin: auto;
}
@media only screen and (min-width: 320px) and (max-width: 600px) {
  .policy-pdf canvas{
    width: 100% !important;
  }
}
.count-info {
  color: #777777;
  font-weight: bold;
  padding-top: 5px;
  padding-bottom: 5px;
  font-size: 16px;
  display: flex;
  align-items: center;
  grid-gap: 4px;
}
.count-info .pi-exclamation-triangle {
  color: #00accb !important;
}
.wv-collapse .p-accordion-header-link{
  background-color: #96d8cd6b !important;
  padding-top: 8px;
  padding-bottom: 8px;
  border: 2px solid white !important;
  border-radius: 4px;
}
.info-field-msg{
  color: #00accb !important;
  font-weight: 500;
  font-size: 12px ;
  font-family: 'Lato', sans-serif;
}
.info-field-msg span{
  color: #00accb !important;
  font-weight: 500;
  font-size: 11px;
}
.info-err-border,
.info-err-border:focus,
.info-err-border:hover,
.info-err-border.p-inputtext:enabled:focus {
  border: 1.3px solid #00accb !important;
}
.info-field-msg.res-exp{
  font-size: 14px !important;
}