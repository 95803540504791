.grid-footer-btn {
  display: flex;
  justify-content: flex-end;
  gap: 10px;
}
.disabled {
  pointer-events: none;
}
.us-mobile-div {
  padding-top: 4.5%;
}
.form-mobile-mr {
  margin-top: 20px;
}
.assign-dropdown {
  width: 100% !important;
}
#dropdown-div {
  width: 22%;
}
.user-access-content {
  padding: 1rem;
}
@media only screen and (min-width: 300px) and (max-width: 500px) {
  #dropdown-div {
    width: 100%;
  }
  .us-mobile-div {
    padding-top: 16%;
  }
  .user-access-content {
    padding: 1rem !important;
  }
  .us-mobile-div .ag-mobile-view,
  .assign-role-div .ag-mobile-view {
    padding-left: 0px;
    padding-right: 0px;
  }
  .us-main-content {
    padding: 2%;
    padding-top: 31%;
  }
}
