.legal-popup .p-dialog-footer {
  padding: 15px;
}
.legal-popup .p-dialog-content {
  border-top: 1px solid #9999;
  border-bottom: 1px solid #9999;
  padding: 0;
}
.legal-popup p {
  text-align: justify;
}
.legal-popup ul {
  list-style-type: square;
}
.legal-popup .p-dialog-content p:nth-child(1) {
  margin-top: 10px;
}

.legal-popup .p-dialog-content > div {
  justify-content: center;
  height: 100%;
  align-items: center;
}
