.user-access-form p {
  text-align: left;
  margin-bottom: 0px;
}
.userOffice-lbl {
  font-family: 'Lato', sans-serif;
  color: #666666;
}
.user-access-form .p-dropdown,
.user-access-form .p-multiselect {
  float: left;
  width: 100% !important;
  height: 42px !important;
  margin-top: 0px !important;
  border: 1px solid #999999 !important;
}
.user-access-form .p-multiselect-label {
  padding: 0px;
}
.user-access-form .p-dropdown-label {
  line-height: 18px;
}
.line-break {
  position: absolute;
  top: 50px;
}
.user-access-form .p-placeholder {
  text-align: left;
  padding-top: 2%;
  padding-left: 5px;
}
@media only screen and (min-width: 300px) and (max-width: 500px) {
  .user-access-form .p-dropdown,
  .user-access-form .p-multiselect {
    width: 98%;
  }
  .form-mobile-mr {
    margin-top: 4% !important;
    display: flex;
    justify-content: flex-end;
  }
}
