#outModal {
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  background: #000000a8;
  z-index: 999;
  height: 100vh;
}

#outModal_inner {
  background-color: white;
  color: black;
  position: absolute;
  top: 3vh;
  right: 35vw;
  padding: 1.6%;
  border-radius: 5px;
  width: 400px;
  padding-bottom: 1.4%;
}

#outModal_inner .pi-exclamation-triangle {
  font-size: 28px;
}

#outModal_inner div {
  display: flex;
}

@media only screen and (min-width: 300px) and (max-width: 500px) {
  #outModal_inner {
    background-color: white;
    color: black;
    position: absolute;
    top: 3vh;
    right: 3vw;
    padding: 2%;
    border-radius: 5px;
    width: 354px;
  }
}
