.nav-border {
  border-right: 1px solid #999999;
  height: 25px;
}
.country-item {
  display: flex;
  grid-gap: 20px;
}
.header-language-div {
  gap: 2px;
  border-right: 1px solid #999999;
  height: 25px;
}
.header-img2 {
  pointer-events: none;
}
.header-language-div img {
  margin-top: -10px;
}
.nav-country-list {
  position: absolute;
  z-index: 1000;
}
.nav-country-list input {
  width: 100%;
}
.nav-border button {
  padding-top: 0px !important;
  margin-top: -3px;
}
#wv-logo {
  margin-top: -8px;
}
.wv-grow {
  flex-grow: 0.94;
  padding: 13px !important;
  align-items: self-end;
  display: flex;
  padding-left: 0px !important;
}
.menu-btn-div {
  width: 4.2%;
  display: flex;
  justify-content: flex-end;
}
#header_img3,
#wv-logo {
  width: 7.5rem;
  cursor: pointer;
}
#header_img1 {
  width: 22px;
}
.p-overlaypanel .p-overlaypanel-content {
  padding: 0px !important;
}
.wv-header {
  box-shadow: 0px 0px 8px -4px #c5c2c2;
  position: fixed;
  width: 100%;
  z-index: 9;
  background-color: #f5f5f5;
  margin-left: 0px;
  padding-top: 3px;
}
.wv-header .p-speeddial {
  display: none;
}
#help_bg {
  background-image: url('../../../assets/images/Help_header.svg');
}
#help_bg:active,
#help_bg:hover {
  background-image: url('../../../assets/images/Help_orange.png');
}
#home_bg {
  background-image: url('../../../assets/images/Home.svg');
}
#home_bg:active,
#home_bg:hover {
  background-image: url('../../../assets/images/Home_orange.png');
}
.header-icons {
  width: 30px;
  height: 20px;
  background-repeat: no-repeat;
  background-size: 20px 19px;
  background-position: bottom;
  cursor: pointer;
}
#logout-bg {
  background-image: url('../../../assets/images/Logout.png');
  background-size: 22px 17px;
}
#logout-bg:hover,
#logout-bg:active {
  background-image: url('../../../assets/images/logout_orange.svg');
  background-size: 22px 18px;
}
.header-logout {
  width: 30%;
}
@media screen and (min-width: 1200px) {
  .p-xl-4 {
    width: 31.3333%;
  }
  .icons-div {
    width: 10.3333%;
  }
}

@media only screen and (min-width: 300px) and (max-width: 768px) {
  .wv-grow {
    flex-grow: 1;
  }
  .header-language-div {
    gap: unset !important;
    cursor: pointer;
    border-right: none;
    justify-content: start;
  }
  .nav-border {
    border-right: none;
  }
  .wv-grow + div {
    padding-left: 0px;
    padding-right: 0px;
  }
  .menu-btn-div {
    width: 11%;
    display: block;
    justify-content: end;
  }
  .icons-div,
  .logo-div {
    display: none;
  }
  #overlay_panel_language {
    left: 20vw !important;
    --overlayArrowLeft: 54vw !important;
  }
  .wv-header .p-speeddial {
    display: block;
    top: 0;
    right: -8px;
  }
  .col-sm-header .p-speeddial button {
    width: unset;
    height: unset;
    background: transparent;
  }
  .col-sm-header {
    width: 40%;
  }
  .col-sm-header button:focus,
  .col-sm-header button:active {
    box-shadow: none;
    color: #f4803b !important;
    background-color: transparent !important;
  }
  .col-sm-header .pi-pencil::before {
    content: url('../../../assets/images/HomeMobile.png');
  }
  .col-sm-header .pi-refresh::before {
    content: url('../../../assets/images/HelpMobile.png');
  }
  .col-sm-header .pi-trash::before {
    content: url('../../../assets/images/LogoutMobile.png');
  }
  .col-sm-header .p-speeddial-action {
    background: #717375;
    width: 45px;
    height: 45px;
  }
}
@media only screen and (min-width: 768px) and (max-width: 1024px) {
  .wv-grow {
    flex-grow: 0.5;
  }
  .header-language-div {
    border-right: 1px solid #999999;
  }
  .nav-border {
    border-left: none;
  }
  .menu-btn-div {
    width: 7%;
    display: block;
    justify-content: flex-end;
  }
}
.header-logout .p-confirm-dialog-accept {
  height: 36px;
}
